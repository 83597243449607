/*
 * @Author: 徐浩然
 * @Date: 2023-11-08 16:10:08
 * @LastEditors: 徐浩然
 * @LastEditTime: 2023-11-08 17:34:47
 * @Descripttion: 项目说明：
 */
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import(/* webpackChunkName: "Home" */ '@/views/MyService.vue'),
  },
  {
    path: '/service',
    name: 'MyService',
    component: () => import(/* webpackChunkName: "MyService" */ '@/views/MyService.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
