<!--
 * @Author: 徐浩然
 * @Date: 2023-11-08 16:10:08
 * @LastEditors: 徐浩然
 * @LastEditTime: 2023-11-08 16:19:37
 * @Descripttion: 项目说明：
-->
<template>
  <router-view />
</template>

<style lang="stylus">

</style>
